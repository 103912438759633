import React from "react"
import { Link } from "gatsby"

const Pagination = ({ link = "journal", numPages, currentPage }) => {
  return (
    <div className="col-lg-12 col-md-12">

      <div className="pagination-area text-center">
        {numPages > 1 && currentPage !== 1 &&
          <Link to={currentPage - 1 === 1 ? `/${link}/` : `/´${link}-${currentPage - 1}/`}
                className="prev page-numbers">
            <i className="bx bx-chevrons-left" />
          </Link>}
        {Array.from({ length: numPages }).map((_, i) => (
          <PaginationEntry link={link} key={"pe-" + i} currentPage={currentPage} index={i} />
        ))
        }
        {numPages > 1 && currentPage !== numPages &&
          <Link to={`/${link}-${currentPage + 1}/`} className="next page-numbers">
            <i className="bx bx-chevrons-right" />
          </Link>}

      </div>
    </div>
  )
}

export default Pagination

const PaginationEntry = ({ link, index, currentPage }) => {
  if (index + 1 === currentPage) {
    return <span className="page-numbers current" aria-current="page">{index + 1}</span>
  }

  if (index + 1 !== currentPage) {
    let rel = ""
    if (index + 1 > currentPage) {
      rel = "next"
    } else if (index + 1 < currentPage) {
      rel = "prev"
    }

    return (<Link rel={rel} to={index + 1 === 1 ? `/${link}/` : `/${link}-${index + 1}/`} className="page-numbers">
      {index + 1}
    </Link>)
  }
}



